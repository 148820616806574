@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Sixtyfour&family=Workbench&display=swap');
:root{
    --color:white;
    --background:#0F0F0F;
    --secondary:#1c1b1b;
    --hover:#1c1b1bdb;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Manrope", sans-serif;
}
body{
    color: var(--color);
    background: var(--background);
}
a{
    color: var(--color);
    text-decoration: none;
}
.fixed-menu {
    position: fixed;
    width: 100%;
    bottom: 50px;
}

.b-menus {
    position: relative;
    width: 40%;
    margin: auto;
    background: var(--background);
    border-radius: 50px;
}

.b-menus::before {
    content: "";
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background: linear-gradient(45deg, red, blue);
    z-index: -1;
    border-radius: 50px;
    filter: blur(10px);
}

.menus {
    width: 100%;
    display: flex;
    margin: auto;
    list-style: none;
    justify-content: space-evenly;
    background: var(--background);
    padding: 15px 0;
    box-shadow: 0 0 10px 1px #ccc;
    border-radius: 50px;
}

.menus li {
    font-size: 30px;
    width: 50px;
    height: 50px;
    background: white;
    color: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    /* box-shadow: 0 0 10px 1px #ccc; */
}
.menus li:hover{
    box-shadow: 0 0 10px 1px #ccc;
}
.menus li > span {
    display: none;
}
.menus li:hover > span {
    display: block;
    font-size: 14px;
    background: var(--background);
    color: var(--color);
    position: absolute;
    top: -40px;
    box-shadow: 0 0 10px 1px var(--background);
    border-radius: 15px;
    padding: 10px 15px;
}


.menus li.active {
    background: var(--background) !important;
    color: white !important;
    box-shadow: 0 0 10px 1px #ccc;

}
.container{
    padding: 50px 100px;
}
.breadcrumb-title{
    font-size: 40px;
    text-transform: uppercase;
}
.breadcrumb-path{
    font-size: 20px;
}
.card,.card-outer{
    width: 200px;
    height: 150px;
    background: linear-gradient(var(--secondary),var(--background),var(--background),var(--secondary),var(--secondary));
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: 0.3s all ;
}

.card-icon{
    height: 130px;
    font-size: 120px;
}
.langorframework{
    font-size: 18px;
    z-index: -1;
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: var(--secondary);
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all ;
}
.card:hover,.langorframework:hover ~ .card{
    /* transform: translateY(-50px); */
    box-shadow: 0 0 1px 1px #cccccc61;
    transition: 0.3s all ;
}
.card-outer:hover .langorframework{
    z-index: 1 !important;
    transition: 0.3s all ;
}


/* Css for Mobile */
@media only screen and (max-width: 768px){
    
.fixed-menu {
    position: fixed;
    width: 100%;
    bottom: 25px;
}

.b-menus {
    position: relative;
    width: 90%;
    margin: auto;
    background: var(--background);
    border-radius: 50px;
}

.b-menus::before {
    content: "";
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background: linear-gradient(45deg, red, blue);
    z-index: -1;
    border-radius: 50px;
    filter: blur(10px);
}

.menus {
    width: 100%;
    display: flex;
    margin: auto;
    list-style: none;
    justify-content: space-evenly;
    background: var(--background);
    padding: 15px 0;
    box-shadow: 0 0 10px 1px #ccc;
    border-radius: 50px;
}

.menus li {
    font-size: 30px;
    width: 50px;
    height: 50px;
    background: white;
    color: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    /* box-shadow: 0 0 10px 1px #ccc; */
}
.menus li:hover{
    box-shadow: 0 0 10px 1px #ccc;
}
.menus li > span {
    display: none;
}
.menus li:hover > span {
    display: none;
    font-size: 14px;
    background: var(--background);
    color: var(--color);
    position: absolute;
    top: -40px;
    box-shadow: 0 0 10px 1px var(--background);
    border-radius: 15px;
    padding: 10px 15px;
}


.menus li.active {
    background: var(--background) !important;
    color: white !important;
    box-shadow: 0 0 10px 1px #ccc;

}
.container{
    padding: 30px 20px;
}
.breadcrumb-title{
    font-size: 35px;
    text-transform: uppercase;
}
.breadcrumb-path{
    font-size: 17px;
}
.card,.card-outer{
    width: 150px;
    height: 125px;
    background: linear-gradient(var(--secondary),var(--background),var(--background),var(--secondary),var(--secondary));
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: 0.3s all ;
}
}