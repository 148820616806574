@import url('https://fonts.googleapis.com/css2?family=Sixtyfour&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sixtyfour&family=Workbench&display=swap');

.home-page{
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--background);
    display: grid;
    grid-template-columns: repeat(2,1fr);

}
.hero-img {
    width: 100%;
}

.imge-bg{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: absolute;
    top: 30%;
    left: 10%;
    transform: translate(-10%, -30%);
}
.imge-bg::before{
    content: "";
    width: 105%;
    height: 105%;
    top: -2.5%;
    bottom: -2.5%;
    left: -2.5%;
    right: -2.5%;
    background: linear-gradient(45deg,red,blue);
    filter: blur(10px);
    position: absolute;
    z-index: -10;
    animation: rotate-border 1s alternate infinite;
    border-radius: 50%;
}
@keyframes rotate-border {
    0%{
        rotate: 0deg;
    }
    100%{
        rotate: 360deg;
    }
}

.imge-bg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid white;
    outline: red 2px solid;
    background: var(--background);
    user-select: none;
}
.detail{
    width: 100%;
    overflow: hidden;
    padding: 20px;
    /* background: rebeccapurple; */
}
.welcome-text{
    text-align: center;
    position: relative;
    margin-top: 100px;
    font-family: "Sixtyfour", sans-serif;
    font-size: 75px;
    font-weight: 200;
    display: inline-block;
    letter-spacing: 10px;
    animation: text-shadow-glow 1s infinite alternate;
}
@keyframes text-shadow-glow {
    0%{
        text-shadow: none;
    }
    100%{
        text-shadow: 2px 2px 10px #ccc,
        -2px -2px 10px #ccc;
    }
}
.intro h2{
    font-size: 40px;
    margin-top: 50px;
    line-height: 150%;
}
.intro .name{
    color: darkorange;
    font-family: "Workbench", sans-serif;
    font-weight: 200;
    text-transform: uppercase;
    display: block;
}
.intro .hello-world{
    font-family: 'Courier New', Courier, monospace;
}
.about-me-button{
    background: linear-gradient(var(--secondary),var(--background),var(--background),var(--secondary));
    padding: 10px 25px;
    font-size: 22px;
    margin-top: 20px;
    color: var(--color);
    text-transform: capitalize;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0 0 10px 1px #cccccc3e;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.icon-arrow{
    position: relative;
    animation:arrow-animate 0.3s infinite alternate;
}
@keyframes arrow-animate {
    0%{
        left: 0;
    }
    100%{
        left: 5px;
    }
}
.about-me-button:hover .icon-arrow{
    position: relative;
    left: 5px;
    transition: 0.3s all;
}
.btn-inner{
    display: inline-block;
}
.icon-arrow{
    font-size: 25px;
    display: block;
    transition: 0.3s all;
}
@media only screen and (max-width: 768px){
    .home-page{
        position: absolute;
        height: 100%;
        width: 100%;
        background: var(--background);
        display: grid;
        grid-template-columns: repeat(1,1fr);
    
    }
    .imge-bg{
        width: 200px;
        height: 200px;
        border-radius: 50%;
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translate(-50%, -15%);
    }
    .welcome-text{
        display: none;
    }
    
    .intro h2{
        font-size: 40px;
        margin-top: 75px;
        line-height: 150%;
        text-align: center;
    }
    .intro .name{
        color: darkorange;
        font-family: "Workbench", sans-serif;
        font-weight: 200;
        text-transform: uppercase;
        display: block;
        font-size: 28px;
    }
}