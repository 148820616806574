.contact-page{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

}
.form-control{
    display: block;
    width:350px;
    position: relative;
    margin-top: 10px;
}
.form-control label{
    position: absolute;
    background: red;
    left: 50%;
    top: 20%;
}
.form-control input,.form-control textarea{
    width: 100%;
    height: 40px;
    background:var(--background);
    color: white;
    outline: none;
    border: 1px solid white;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 10px;

}
.form-control textarea::-webkit-scrollbar{
    width: 0;
}
.form-control input::placeholder,.form-control textarea::placeholder{
    color: white;
}
.form-control textarea{
    resize: none;
    width: 100%;
    background:var(--background);
    height: 150px;
}
.form-control label{
    position: absolute;
    left:0;
}
.submit-btn{
    background: linear-gradient(var(--secondary),var(--background),var(--background),var(--secondary));
    padding: 10px 25px;
    font-size: 18px;
    margin-top: 20px;
    color: var(--color);
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0 0 10px 1px #cccccc3e;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 255px;
    height: 50px;
    text-transform: uppercase;
    transition: 0.3s all;
}
.submit-btn:hover{
    background: var(--hover);
}