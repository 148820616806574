.sections-cards{
    margin: 20px 0;
}

.cards-outer{
    display: flex;
    justify-content: space-around;
}
.cards{
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    gap: 20px;
}
.types{
    margin-left: 19px;
    display: flex;
    gap: 20px;
}
.types button{
    background: var(--background);
    border: 2px solid var(--secondary);
    width: 150px;
    height: 50px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}
.types button.active{
    background: var(--secondary);
    border: 2px solid var(--secondary);
    width: 150px;
    height: 50px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 0 10px 1px #cccccc61;
}
@media only screen and (max-width: 480px){
    .cards{
        margin: 20px 0;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
    }
    .types{
        margin-left: 0px;
        display: flex;
        gap: 20px;
    }
    .types button{
        background: var(--background);
        border: 2px solid var(--secondary);
        width: 120px;
        height: 50px;
        color: white;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        padding: 5px 5px;
    }
    .types button.active{
        background: var(--secondary);
        border: 2px solid var(--secondary);
        width: 120px;
        height: 50px;
        color: white;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        box-shadow: 0 0 10px 1px #cccccc61;
    }
    .skills-page{
        padding-bottom: 100px;
    }
}