.blogs-page {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.pagination-arrows {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 45px;

}

.BlogPost {
    width: 300px;
    height: 390px;
    box-shadow: 0 0 10px 1px #cccccc61;
    position: relative;
}

.post-img {
    width: 300px;
    height: 250px; /* Fixed height */
    position: relative;
    overflow: hidden; /* Hide overflow */
}

.post-img img {
    width: 100%;
    height: 100%; /* Set height to 100% */
    object-fit: cover; /* Ensure image covers the container */
}

.post-detail {
    margin: 5px 0;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 14px;
}

.post-title {
    margin-top: 10px;
    padding: 5px 15px;
    height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.left-arr, .right-arr {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    font-size: 30px;
}

.left-arr {
    left: -65px; /* Adjust as needed */
}

.right-arr {
    right: -65px; /* Adjust as needed */
}

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader {
    text-align: center;
  }
  
  .post-thumbnail{
    width:60%;
    height: auto;
    margin:50px auto;
    border: 2px solid white;
    border-radius: 10px;
    object-fit: cover;

  }
  .post-thumbnail img{
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  .post-content{
    letter-spacing: 2px;
    word-spacing: 5px;
    line-height: 30px;
    padding-bottom: 150px;
  }
  .post-content li{
    margin-left: 50px;
  }

  .post-content h1{
    font-size: 36px;
  }
  .post-content h2{
    font-size: 28px;
  }

  .blog-title{
    font-size: 40px !important;
  }
  @media only screen and (max-width: 768px){
    .pagination-arrows {
      position: relative;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 45px;
  
  }
  .blogs-page {
    margin-top: 50px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    gap: 20px;
}
.blog-title{
  font-size: 25px !important;
}
.post-thumbnail{
  width:100%;
  height: auto;
  margin:50px auto;
  border: 2px solid white;
  border-radius: 10px;
  object-fit: cover;

}

  }