.about-page{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.about-img {
    width: 100%;
}

.about-imge-bg{
    width: 250px;
    height: 250px;
    border-radius: 50%;
    position: relative;
    margin-top: 10%;
    
}

.about-imge-bg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid white;
    outline: red 2px solid;
    background: var(--background);
    user-select: none;
}
.social-icons{
    display: flex;
    gap: 20px;
    margin-top: 20px;
    
}
.social-icons span{
    display: none;
}
.social-icons li{
    list-style: none;
    font-size: 35px;
    background: var(--secondary);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.social-icons li:hover{
    background: var(--hover);
    cursor: pointer;
}
.about-name{
    text-align: center;
    text-transform: uppercase;
    font-size: 50px;
    letter-spacing: 5px;
    font-family:'Courier New', Courier, monospace;
}
.about-me--paragraph{
    margin-top: 20px;
    font-size: 16px;
    word-spacing: 3px;
    line-height: 200%;
    text-align: justify;
}
.download-re{
    margin-top: 20px;
    
}
.download-re button{
    
        background: linear-gradient(var(--secondary),var(--background),var(--background),var(--secondary));
        padding: 10px 25px;
        font-size: 18px;
        margin-top: 20px;
        color: var(--color);
        
        border-radius: 50px;
        border: none;
        cursor: pointer;
        font-weight: bold;
        box-shadow: 0 0 10px 1px #cccccc3e;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        width: 255px;
        height: 50px;
        text-transform: uppercase;
        

}
@media only screen and (max-width: 768px){
    .about-page{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        padding-bottom: 100px;
    }
    .about-img{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
    }
    .about-name{
        text-align: center;
        text-transform: uppercase;
        font-size: 33px;
        letter-spacing: 2px;
        font-family:'Courier New', Courier, monospace;
    }
    .about-me--paragraph{
        margin-top: 20px;
        font-size: 14px;
        word-spacing: 3px;
        line-height: 200%;
        text-align: justify;
    }
}