.project-page {
    margin: 50px 0;
    display: flex;
    justify-content: center;
}

.projects-container {
    display: flex;
    gap: 1.5rem;
    overflow-x: scroll;
    padding: 20px 15px;
    border-radius: 5px;
    box-shadow: 0 0 2px 1px #eee;
}
.projects-container::-webkit-scrollbar {
    width: 0;
    display: none;
}


.title-cont {
    padding: 5px 10px;
    width: 100%;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide any overflowing content */
    text-overflow: ellipsis; /* Truncate text with ellipsis */
    height: 3rem;
}

.img-container {
    width: 400px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    

    img {
        width: 100%;
        border-radius: 20px 20px 0 0;
        
    }
}

.project-box {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 20px;
    
}

.viewbutton {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;

    button {
        display: block;
        width: 100%;
        height: 3rem;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        background: white;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        &:hover{
            background: linear-gradient(45deg,rgb(245, 105, 105),rgb(113, 113, 255));
            color: white;
        }
    }
    
}

@media only screen and (max-width: 768px){

    .projects-container {
        display: flex;
        gap: 1.5rem;
        overflow-x: scroll;
        padding: 20px 15px;
        border-radius: 5px;
        box-shadow: 0 0 2px 1px #eee;
        height: 23rem;
    }
    .title-cont {
        position: relative;
        width: 300px;
        height: 2.5rem;
        h4{
            text-overflow: ellipsis;
        }
    }
    .project-box {
        position: relative;
        border: 1px solid #ccc;
        border-radius: 20px;
        width: 305px;
        
    }
    .img-container {
        width: 305px;
        position: relative;
        border-radius: 20px;
    
    
        img {
            width: 100%;
            border-radius: 20px 20px 0 0;
    
        }
    }
}